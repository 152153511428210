$(document).ready(function () {
    $(document).on('click', '.home-cars-tab a',
        function (e) {
            e.preventDefault();
            var form = $("#pricing-filter-form");
            $('input[name="IsCar"]').val('true');
            $('.home-cars-tab').addClass('active');
            $('#van-search').removeClass('in active clearfix');
            $('#car-search').addClass('in active clearfix');
            $('.home-vans-tab').removeClass('active');
            cookies.set("vt", "Cars", 30);
            $.ajax(
                {
                    url: form.attr('action'),
                    data: {
                        "PricingType": "Business",
                        "VehicleType": "Cars"
                    },
                    type: "GET",
                    success: function (data) {
                        $('.u-textCentre.filter-bar-title').html('<h1>THIS WEEK\'S <a>TOP 20 CAR LEASE DEALS<a/></h1>');
                        $(".home-carousel").html(data);
                        slickInit();
                    },
                    error: function () {
                        $('.home-vans-tab').addClass('active');
                        $('#car-search').removeClass('in active clearfix');
                        $('#van-search').addClass('in active clearfix');
                        $('.home-cars-tab').removeClass('active');
                    }
                });
            var searchForm = $(".vehicle-search__form:visible");
            $.post(searchForm.data("filterroute"), searchForm.serialize(), function (response) {
                var umbRouting = searchForm.find("input[name=ufprt]").clone();
                searchForm.html(response).append(umbRouting);
                searchForm.find('.selectpicker').selectpicker('refresh');
            });

        });

    $(document).on('click', '.home-vans-tab a',
        function (e) {
            e.preventDefault();
            var form = $("#pricing-filter-form");
            $('input[name="IsCar"]').val('false');
            $('.home-vans-tab').addClass('active');
            $('#car-search').removeClass('in active clearfix');
            $('#van-search').addClass('in active clearfix');
            $('.home-cars-tab').removeClass('active');
            cookies.set("vt", "Vans", 30);
            $.ajax(
                {
                    url: form.attr('action'),
                    data: {
                        "PricingType": "Business",
                        "VehicleType": "Vans"
                    },
                    type: "GET",
                    success: function (data) {
                        $('.u-textCentre.filter-bar-title').html('<h1>THIS WEEK\'S <a>TOP 20 VAN LEASE DEALS<a/></h1>');
                        $(".home-carousel").html(data);
                        slickInit();
                    },
                    error: function() {
                        $('.home-cars-tab').addClass('active');
                        $('#van-search').removeClass('in active clearfix');
                        $('#car-search').addClass('in active clearfix');
                        $('.home-vans-tab').removeClass('active');
                    }
                });

            var searchForm = $(".vehicle-search__form:visible");
            $.post(searchForm.data("filterroute"), searchForm.serialize(), function (response) {
                var umbRouting = searchForm.find("input[name=ufprt]").clone();
                searchForm.html(response).append(umbRouting);
                searchForm.find('.selectpicker').selectpicker('refresh');
            });
        });


    $(document).on('click', '#business_button',
        function (e) {
            var form = $("#pricing-filter-form");
            $.ajax(
                {
                    url: form.attr('action'),
                    data: {
                        "PricingType": "Business",
                        "VehicleType": $('.hidden-xs.tabs.vehicle-search__tabs.clearfix').find('.active').text()
                    },
                    type: "GET",
                    success: function (data) {
                        $(".home-carousel").html(data);
                        slickInit();
                    }
                });
        });

    $(document).on('click', '#personal_button',
        function (e) {
            var form = $("#pricing-filter-form");
            $.ajax(
                {
                    url: form.attr('action'),
                    data: {
                        "PricingType": "Personal",
                        "VehicleType": $('.hidden-xs.tabs.vehicle-search__tabs.clearfix').find('.active').text()
                    },
                    type: "GET",
                    success: function (data) {
                        $(".home-carousel").html(data);
                        slickInit();
                    }
                });
        });

    function slickInit() {
        $('.js-slick-slider').each(function () {
            var $this = $(this);
            $this.slick({
                autoplay: $this.data('slick-autoplay') || false,
                autoplaySpeed: $this.data('slick-autoplay-speed') || 4000,
                speed: $this.data('slick-speed') || 800,
                slidesToShow: $this.data('slick-slides-to-show') || 3,
                slidesToScroll: $this.data('slick-slides-to-scroll') || 1,
                dots: $this.data('slick-dots') || false,
                arrows: $this.data('slick-arrows') === undefined ? true : false,
                responsive: $this.data('slick-responsive') || [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            "centerMode": true,
                            "centerPadding": '10px'
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            "centerMode": true,
                            "centerPadding": '10px'
                        }
                    },
                ],
                onBeforeChange: function (slick, curIndex, nextIndex) {
                    $this.trigger('beforeChange.slick', [curIndex, nextIndex]);
                },
                onAfterChange: function () {
                    $this.trigger('afterChange.slick');
                }
            });
        });
    }

});